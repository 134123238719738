import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`High-Level concepts`}</h1>
    <p>{`Before diving in the capabilities of the enableHR API, it is important to understand the basics concepts used in the enableHR product.`}</p>
    <p>{`When an organisation signs up to enableHR, an enableHR Account is created.
Within this account, worker records (Candidates and Employees currently supported) are organised in branches, which are either a legal entity or a department. `}</p>
    <p>{`Each record has its own set of data fields. Some of these fields are identical across record types (first name for instance), some are restricted to a specific type (start date for instance).`}</p>
    <p>{`The enableHR API exposes Account information, Branch information, Candidate information and Employee information.`}</p>
    <p>{`In order to access this information, you need to have the appropriate rights. This is done via our Authentication and Authorisation Server. Our Client Success team will help you with it.`}</p>
    <p>{`enableHR has 4 types of records: Candidates, Employees, Volunteers and Contractors.
At the present moment, the enableHR API only exposes Candidates and Employees `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      